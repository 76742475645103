const store = {
  id: 'cotton-on-kids',
  name: 'COTTON ON - Kids',
  model:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/store-models/cottonon-kids.glb',
  modelId: 'beba670c423244bcbcef7ee844310da1',
  storeFrontId: '10a5e176c1bb435b8da5928525b17bc4',
  storeLogo:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/assets/logo.svg',
  storeWelcomeText: 'Welcome to the Cotton On Kids Experience.',
  storeFavIcon:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-westfield-geelong/cotton-on.ico',

  cameraRotation: { azimuthal: 93, polar: 90 },
  rotationDegrees: 178,
  menus: [
    {
      title: 'Entrance',
      locationId: '10a5e176c1bb435b8da5928525b17bc4',
      options: {
        rotation: { azimuthal: 7.899000228455016, polar: 1.4964751814539934 },
      },
    },
  ],
  panoImageBaseUrl:
    'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/location-images-tiled-2k/',
    baseUrl4k:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/location-images-tiled-4k/',
    baseUrl2k:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/location-images-tiled-2k/',
  },
  layoutSettings: {
    modalAlignment: 'center',
  },
};

export default store;
