import useModal from '@features/layout/modal/hooks/useModal';
import { MediaModalBody } from '@features/media';
import { ProductModalBody } from '@features/products';
import { animated, config, useSpring } from '@react-spring/three';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';

import { Billboard, Html, Line, Text, useCursor } from '@react-three/drei';

import useTag from '../hooks/useTag';
import useTagsStatus from '../hooks/useTagsStatus';
import TagStem from './TagStem';

const useStyles = makeStyles((theme) => ({
  tagTitle: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '-0.017em',
    textTransform: 'uppercase',
    color: '#fff',
    padding: '0px 4px',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
  },
  tagDescription: {
    fontSize: '13px',
    letterSpacing: '-0.017em',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
  },
}));

const Tag = (props) => {
  const { tagData, stemEndPosition } = props;
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(true);
  const { activeTagIds, focusTagIds } = useTagsStatus();
  useCursor(hovered);
  const { handleTagClick } = useTag();

  const { open } = useModal();

  // useEffect(() => {
  //   if (focusTagIds && focusTagIds.includes(tagData.id)) {
  //     setFocus(true);
  //   } else {
  //     setFocus(false);
  //   }
  // }, [focusTagIds]);

  useEffect(() => {
    if (activeTagIds && activeTagIds.includes(tagData.id)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeTagIds]);

  const { scale, rotation, borderScale, dotScale } = useSpring({
    scale: hovered || active ? 1 : 0,
    rotation: hovered || active ? [0, 0, Math.PI / 8] : [0, 0, 0],
    borderScale: focus ? 1 : 0.7,
    dotScale: focus ? 1 : 0.55,
    config: config.gentle,
  });

  const handleActiveTag = useCallback(
    (e, tag) => {
      e.stopPropagation();
      const modalBody = tag?.type === 'price' ? <ProductModalBody /> : <MediaModalBody />;
      handleTagClick(tag, () => {
        open(modalBody);
      });
    },
    [open]
  );

  const handlePointerOver = useCallback(() => {
    setHovered(true);
  }, []);

  const handlePointerOut = useCallback(() => {
    setHovered(false);
  }, []);

  const [showTagDescription, setShowTagDescription] = useState(false);

  useEffect(() => {
    if (tagData.seriesName && tagData.name && tagData.description) {
      setShowTagDescription(true);
    }
  }, [tagData]);

  return (
    <group position={[0, 0, 0]}>
      <TagStem stemEndPosition={stemEndPosition}></TagStem>
      <group
        position={stemEndPosition}
        scale={0.065}
        onClick={(e) => {
          handleActiveTag(e, tagData);
        }}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <Billboard follow={true} position={[0, 0, 0]}>
          <animated.group rotation={rotation} renderOrder={3}>
            <Text
              font={
                'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff'
              }
              text={tagData.type === 'price' ? 'assignment' : 'play_circle'}
              fontSize={1.25}
              anchorX="center"
              anchorY="middle"
              color={focus ? '#ee1f3b' : '#fff'}
              renderOrder={3}
            />
          </animated.group>
          <animated.group scale={scale} renderOrder={2} visible={showTagDescription}>
            <Line
              points={[
                [0, 0, 0],
                [0, 1.5, 0],
                [4, 1.5, 0],
              ]}
              color="#fff"
              opacity={1}
              lineWidth={3}
              transparent
              depthWrite={false}
            />
            <Html
              position={[0, 2.75, 0]}
              transform
              zIndexRange={[0, 0]}
              scale={2}
              renderOrder={2}
              style={{
                // width: '100%',
                pointerEvents: 'none',
                opacity: showTagDescription ? 1 : 0,
              }}
            >
              <div style={{ position: 'relative', left: '50%' }}>
                <div>
                  <span
                    className={classes.tagTitle}
                    style={{
                      background: '#000000',
                      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
                      fontWeight: '700',
                    }}
                  >
                    {tagData.seriesName}
                  </span>
                  <span className={classes.tagTitle}>{tagData.name}</span>
                </div>
                <div>
                  <span className={classes.tagDescription}>{tagData.description}</span>
                </div>
              </div>
            </Html>
          </animated.group>
          <animated.mesh renderOrder={2} scale={dotScale}>
            <circleGeometry args={[0.65, 32]} />
            <meshStandardMaterial
              color={'#fff'}
              depthWrite={true}
              transparent
              opacity={1}
              alphaToCoverage
            />
          </animated.mesh>
          <animated.mesh renderOrder={1} scale={borderScale}>
            <circleGeometry args={[1, 32]} />
            <meshStandardMaterial
              color={'#000'}
              depthWrite={true}
              transparent
              opacity={0.5}
              alphaToCoverage
            />
          </animated.mesh>
        </Billboard>
      </group>
    </group>
  );
};

export default memo(Tag);
