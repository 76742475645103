const store = {
  id: 'default',
  name: 'COTTON ON - Chadstone',
  model:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/store-models/space5.glb',
  modelId: 'beba670c423244bcbcef7ee844310da1',
  storeFrontId: '7e70bf787a6a4f05803dbfb4fb9ed7ec',
  storeLogo:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/assets/cotton-on-logo.svg',
  storeWelcomeText: 'Welcome to the Cotton On Experience.',
  storeFavIcon:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/assets/cotton-on.ico',

  cameraRotation: { azimuthal: 55, polar: 90 },
  rotationDegrees: 178,
  menus: [
    {
      title: 'Entrance',
      locationId: '7e70bf787a6a4f05803dbfb4fb9ed7ec',
      options: {
        rotation: { azimuthal: 0.9599310885968813, polar: 1.5707963267948966 },
      },
    },
  ],
  panoImageBaseUrl:
    'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl512:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/location-images-tiled-2k/',
    baseUrl2k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/location-images-tiled-2k/',
    baseUrl4k:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/location-images-tiled-4k/',
  },
  layoutSettings: {
    modalAlignment: 'center',
  },
};

export default store;
