import MainView from '@pages/Space/MainView';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router';

import { AppProvider } from './providers/AppProvider';

export default function App() {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname + location.search;

    ReactGA.send({ hitType: 'pageview', page: path });
  }, [location]);

  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
}

function AppContent() {
  return (
    <Routes>
      <Route path="/" element={<MainView />} />
      <Route path="/:spaceAlias" element={<MainView />} />
      <Route path="/:spaceAlias/:locationId" element={<MainView />} />
    </Routes>
  );
}
