const tags = [
  {
    id: '51',
    type: 'video',
    face: { x: -0.10789286649761945, y: 0.9022462691291628, z: -0.417505448109882 },
    position: { x: 17.004288581452347, y: 1.2180853535660199, z: -4.29088605497477 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/51.MOV',
    },
  },
  {
    id: '52',
    type: 'video',
    face: { x: 0.09284662019239374, y: 0.990356353670332, z: 0.10282897385296418 },
    position: { x: 13.626180269462164, y: 0.8897239837327746, z: -5.33247091790207 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/52.MOV',
    },
  },
  {
    id: '53',
    type: 'video',
    face: { x: 0.03523092792226685, y: -0.03832576907593039, z: 0.9986440392564682 },
    position: { x: 12.695786397791531, y: 1.0844169432556185, z: -7.64034645583573 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/53.MOV',
    },
  },
  {
    id: '54',
    type: 'video',
    face: { x: 0.14197354414393654, y: 0.2923490388853527, z: 0.9457143079313226 },
    position: { x: 10.505502389653829, y: 1.367830741634302, z: -5.268375626731856 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/54.MOV',
    },
  },
  {
    id: '55',
    type: 'video',
    face: { x: -0.018973016544309888, y: 0.9981147615517458, z: 0.05836906214520714 },
    position: { x: 12.283774452091057, y: 1.053598680263598, z: -2.7132002446704275 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/55.MOV',
    },
  },
  {
    id: '56',
    type: 'video',
    face: { x: 0.5841820936322286, y: 0.8115699446778983, z: 0.009246965717414301 },
    position: { x: 6.827862774207267, y: 1.1328762551032043, z: -2.0414862290454 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/56.MOV',
    },
  },
  {
    id: '57',
    type: 'video',
    face: { x: -0.041841480970729546, y: 0.9503821478900054, z: 0.30825811172158296 },
    position: { x: 6.514531218493445, y: 1.1312234084270028, z: -4.806487553218119 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/57.MOV',
    },
  },
  {
    id: '58',
    type: 'video',
    face: { x: 0.2590507852914382, y: 0.9642017945235892, z: 0.0566355902006822 },
    position: { x: 1.4681466115952326, y: 0.9037152547733227, z: -4.74717946580308 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/58.MOV',
    },
  },
  {
    id: '59',
    type: 'video',
    face: { x: 0.9996349692705159, y: 0.000004069466657979219, z: -0.02701718332791957 },
    position: { x: -4.12549307074238, y: 1.7351466056721825, z: -3.8216859253785795 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/59.MOV',
    },
  },
  {
    id: '60',
    type: 'video',
    face: { x: 0.520271635222894, y: -0.03153914252428426, z: -0.8534182492021887 },
    position: { x: 11.039572923446613, y: 0.9646706406346186, z: 1.173865934195533 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/60.MOV',
    },
  },
  {
    id: '61',
    type: 'video',
    face: { x: 0.6443272673108208, y: 0.7580416419756671, z: -0.10107047853158886 },
    position: { x: 1.713868925978374, y: 1.1791166085601335, z: 0.6456887727170211 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/61.MOV',
    },
  },
  {
    id: '62',
    type: 'video',
    face: { x: -0.020625076991683765, y: 0.674267233169643, z: -0.7381993663454617 },
    position: { x: 6.815461891958846, y: 0.9719557260433866, z: -0.22439011714229795 },
    media: {
      src: 'https://hqfidelitystorageprod.blob.core.windows.net/cottononkids/default/videos/62.MOV',
    },
  },
];

export default tags;
