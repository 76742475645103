import useBackgroundMusic from '@hooks/useBackgroundMusic';
import useSpaceContext from '@hooks/useSpaceContext';
import { cameraStateObserver } from '@utils/cameraState.observer';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { Box, Button, Fade, LinearProgress, Typography, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useProgress } from '@react-three/drei';

const useStyles = makeStyles((theme) => ({
  '@keyframes fade-in ': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes fade-out ': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  dotContainer: {
    display: 'inline-block',
    width: 18,
    textAlign: 'left',
  },
  animateDot: {
    display: 'inline-flex',
    overflow: 'hidden',
    fontSize: 24,
    animation: `$typing 1.5s linear infinite`,
  },
  backgroundRoot: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2001,
  },
  backdropImageRoot: ({ isLoaded, backgroundImage }) => ({
    width: '100%',
    height: '100%',
    background: `url(${backgroundImage})`,
    backgroundSize: 'cover !important',
    backgroundPosition: 'center !important',
    opacity: isLoaded ? 0 : 1,
    transition: 'opacity 1s',
    zIndex: 1300,
    position: 'absolute',
  }),
  backdrop: {
    position: 'relative',
    backdropFilter: 'blur(8px)',
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: 1301,
    alignItems: 'center',
    justifyContent: 'center',
    color: ' #ffffff',
  },
  container: {
    position: 'absolute',
    minWidth: 250,
    maxWidth: 480,
    width: '100%',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    height: '50vh',
    bottom: 0,
  },
  progressbarRoot: {
    marginTop: 16,
    height: 14,
    borderRadius: 10,
    marginLeft: 16,
    marginRight: 16,
  },
  progressbarColorPrimary: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    borderRadius: 10,
  },
  progressbarBar: {
    borderRadius: 10,
    backgroundColor: '#fff',
  },
  determinate: {
    borderRadius: 10,
  },
  bar1Determinate: {
    borderRadius: 10,
  },
  welcomeTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  welcomeText: {
    color: '#fff',
    fontWeight: '400',
    // marginLeft: 16,
    // marginRight: 16,
    textAlign: 'center',
    width: '100%',
    fontSize: '1.45rem',
    paddingBottom: '18px',
  },
  buttonRoot: {
    borderRadius: 0,
    background: '#000',
    color: '#fff',
    padding: '8px 16px 8px 24px',
    marginTop: 8,
    width: 147,
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: 1,
      transition: `width 0.5s`,
      width: '85%',
      fontWeight: '300',
    },
    '& .MuiButton-endIcon': {
      margin: 0,
    },
    '&:hover': {
      background: '#000',
      color: '#fff',
      '& .MuiButton-label': {
        width: '100%',
      },
    },
  },
  fadeIn: {
    animation: `$fade-in 0.7s linear`,
  },
  fadeOut: {
    animation: `$fade-out 0.5s linear`,
  },
}));

export const SplashScreen = ({ setAnimateLogo }) => {
  const { spaceId, spaceData } = useSpaceContext();
  const [progressNumber, setProgressNumber] = useState(0);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const { progress } = useProgress();
  const [isLoaded, setIsLoaded] = useState(false);

  // const bgImage = '/assets/imgs/linen-house-bg.jpg';

  const factorie = '/assets/imgs/landing-cotton-on.png';

  const classes = useStyles({ isLoaded: isLoaded, backgroundImage: factorie });

  const { playBackgroundMusic } = useBackgroundMusic();

  let showLoadingScreenTimeout;
  let progressNumberInterval;

  useEffect(() => {
    if (spaceId) {
      setShowLoadingScreen(true);
      setProgressNumber(0);
    }
  }, [spaceId]);

  useEffect(() => {
    const calculateProgressNumber = () => {
      let maxNumber = 0;
      let delay = 600;
      if (progress === 0) {
        maxNumber = 35;
      } else if (progress > 30 && progress < 75) {
        maxNumber = 75;
        delay = 500;
      } else if (progress === 100) {
        maxNumber = 100;
        delay = 25;
      }
      progressNumberInterval = setInterval(() => {
        setProgressNumber((prevProgress) =>
          prevProgress >= maxNumber ? prevProgress + 0 : prevProgress + 1
        );
      }, delay);
    };

    calculateProgressNumber();

    return () => {
      clearInterval(progressNumberInterval);
    };
  }, [progress]);

  useEffect(() => {
    if (showLoadingScreen) setAnimateLogo(false);
    return () => {
      if (!showLoadingScreen) {
        clearTimeout(showLoadingScreenTimeout);
        clearInterval(progressNumberInterval);
      }
    };
  }, [showLoadingScreen]);

  useEffect(() => {
    if (progressNumber === 100) {
      showLoadingScreenTimeout = setTimeout(() => {
        setIsLoaded(true);
      }, 600);
    }
  }, [progressNumber]);

  const handleClose = () => {
    setAnimateLogo(true);
    setShowLoadingScreen(false);
    playBackgroundMusic();

    cameraStateObserver.changeStateToSleep();
  };

  return (
    <Fade in={showLoadingScreen} timeout={{ enter: 0, exit: 700 }}>
      <div className={classes.backgroundRoot}>
        <div className={classes.backdropImageRoot}></div>
        <div className={classes.backdrop}>
          <div className={classes.container}>
            <Box
              sx={{
                padding: '16px 0',
                display: 'block',
                position: 'relative',
                width: '100%',
                height: 72,
              }}
            >
              {!isLoaded ? (
                <LinearProgress
                  classes={{
                    root: classes.progressbarRoot,
                    colorPrimary: classes.progressbarColorPrimary,
                    bar: classes.progressbarBar,
                    determinate: classes.determinate,
                    bar1Determinate: classes.bar1Determinate,
                  }}
                  variant="determinate"
                  value={progressNumber}
                />
              ) : (
                <Box
                  className={clsx(
                    classes.welcomeTextContainer,
                    isLoaded ? classes.fadeIn : classes.fadeOut
                  )}
                >
                  <Typography variant="subtitle1" className={classes.welcomeText}>
                    {`${spaceData?.welcomeText}`}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    classes={{ root: classes.buttonRoot }}
                    endIcon={<NavigateNextIcon />}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
    </Fade>
  );
};
