import React, { useCallback } from 'react';
import { useState } from 'react';

export const LocationPoint = (props) => {
  const { locationData, handleClickPoint, ringGeometry } = props;
  const [hovered, setHovered] = useState(false);

  const handlePointerOver = useCallback(() => {
    setHovered(true);
  }, []);

  const handlePointerOut = useCallback(() => {
    setHovered(false);
  }, []);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (handleClickPoint) {
        handleClickPoint(locationData);
      }
    },
    [handleClickPoint, locationData]
  );

  return (
    <group
      position={[locationData.position.x, 0, locationData.position.z]}
      onClick={handleClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <group position={[0, 0.05, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh>
          <circleBufferGeometry args={[0.5, 48, 48]} />
          <meshBasicMaterial color={'white'} depthWrite={false} colorWrite={true} />
        </mesh>
        <mesh scale={0.25} geometry={ringGeometry} renderOrder={2}>
          <meshStandardMaterial
            // color={hovered ? '#8854d0' : '#a55eea'}
            // color={hovered ? '#54a0ff' : '#2e86de'}
            color={hovered ? '#FFFFFF' : '#747d8c'}
            depthWrite={true}
            transparent
            opacity={0.6}
            alphaToCoverage
          />
        </mesh>
      </group>
    </group>
  );
};
