import useSpaceContext from '@hooks/useSpaceContext';
import resourceManager from '@utils/resource.manager';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import { useIntersect } from '@react-three/drei';

import '../utils/ImageShader';
import geometryHelper from '../utils/geometry.helper';

function ImageTile(props) {
  const { minPosition, maxPosition, textureUrl } = props;
  const [texture, setTexture] = useState();
  const { spaceId } = useSpaceContext();
  const hasVisible = useRef(false);
  const geom = useMemo(() => geometryHelper.createPlaneGeometry(minPosition, maxPosition), []);
  const textureLoader = useMemo(() => new TextureLoader(), []);
  const timeStamp = useRef(Date.now());

  const ref = useIntersect((visible) => {
    if (!hasVisible.current && visible) {
      hasVisible.current = true;
      loadTexture();
    }
  });

  useEffect(() => {
    timeStamp.current = Date.now();
    hasVisible.current = false;
    setTexture(undefined);
  }, [textureUrl]);

  async function createTexture(textureUrl, localTimeStamp) {
    const locationImg = await resourceManager.getResource(spaceId, textureUrl);
    if (localTimeStamp == timeStamp.current) {
      const texture = await textureLoader.loadAsync(locationImg);

      texture.minFilter = THREE.LinearFilter;
      texture.flipY = true;
      texture.needsUpdate = true;

      return texture;
    } else {
      return undefined;
    }
  }

  const loadTexture = useCallback(async () => {
    const tmpTimeStamp = timeStamp.current;
    const texture = await createTexture(textureUrl, tmpTimeStamp);
    if (tmpTimeStamp == timeStamp.current) {
      setTexture(texture);
    }
  }, [textureUrl]);

  useEffect(() => {
    const dispose = () => {
      if (texture) {
        texture.dispose();
        // console.log('dispose ImageTile texture');
      }
    };
    window.addEventListener('beforeunload', () => dispose());

    return () => {
      window.removeEventListener('beforeunload', () => dispose());
      dispose();
    };
  }, [texture]);

  return (
    <mesh geometry={geom} renderOrder={100} ref={ref} dispose={null}>
      {texture ? (
        <imageShader texture1={texture} side={THREE.DoubleSide} dispFactor={1} dispose={null} />
      ) : (
        <meshBasicMaterial transparent opacity={0} />
      )}
    </mesh>
  );
}
export default ImageTile;
