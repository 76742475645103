import useSpaceContext from '@hooks/useSpaceContext';
import { cameraStateObserver } from '@utils/cameraState.observer';
import navigationManager from '@utils/navigation.manager';
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { BoxGeometry, Vector3 } from 'three';

import { useThree } from '@react-three/fiber';

import ImageTile from './ImageTile';

const deg2rad = (degrees) => degrees * (Math.PI / 180);

function LocationTiledImageCube(props, ref) {
  const rotationOffset = 0;
  const { locationData, baseImagePath, imageFilePrefix = '4k_face', onReady, segments = 8 } = props;
  const [rotate, setRotate] = useState([]);
  const [tilePositions, setTilePosition] = useState([]);
  const meshRef = useRef();
  const tilesGroupRef = useRef();
  const { spaceData } = useSpaceContext();

  const boxGeometry = useMemo(() => {
    const geometry = new BoxGeometry(20, 20, 20, segments, segments, segments);
    return geometry;
  }, []);

  const vec3 = useMemo(() => new Vector3(), []);
  const { camera } = useThree();

  const enableLayersTimeout = useRef();

  const createTilePositionSettings = useCallback(() => {
    const totalFacesPerTile = 2;
    const totalIndicesPerFace = 3;
    const totalTilesPerCubeFace = segments * segments;
    const imageFaceByCubeFaceIndex = [2, 4, 0, 5, 1, 3];
    const position = boxGeometry.getAttribute('position');

    function createTilePositionSetting(tileIndex) {
      const firstGeometryIndexOnTile = tileIndex * totalFacesPerTile * totalIndicesPerFace;

      let geoIndex = firstGeometryIndexOnTile;
      let x = [],
        y = [],
        z = [];
      for (let i = 0; i < totalFacesPerTile; i++) {
        for (let vertexIndex = 0; vertexIndex < position.itemSize; vertexIndex++) {
          let attributeIndex = boxGeometry.index.array[geoIndex];
          x.push(position.getX(attributeIndex));
          y.push(position.getY(attributeIndex));
          z.push(position.getZ(attributeIndex));
          geoIndex++;
        }
      }

      let cubeFaceIndex = Math.floor(tileIndex / totalTilesPerCubeFace);
      let rowIndex = Math.floor((tileIndex % totalTilesPerCubeFace) / segments);
      let colIndex = Math.floor((tileIndex % totalTilesPerCubeFace) % segments);

      let minPosition = vec3.clone();
      let maxPosition = vec3.clone();
      minPosition.set(Math.min(...x), Math.min(...y), Math.min(...z));
      maxPosition.set(Math.max(...x), Math.max(...y), Math.max(...z));

      let tilePosition = {
        textureUrl: `${baseImagePath}${locationData.locationKey}/${imageFilePrefix}${imageFaceByCubeFaceIndex[cubeFaceIndex]}_${colIndex}_${rowIndex}.jpg`,
        minPosition: minPosition,
        maxPosition: maxPosition,
      };
      return tilePosition;
    }

    if (locationData?.locationKey) {
      let tilePositionSettings = [];
      for (let tileIndex = 0; tileIndex < totalTilesPerCubeFace * 6; tileIndex++) {
        tilePositionSettings.push(createTilePositionSetting(tileIndex));
      }
      setTilePosition(tilePositionSettings);
      // console.log('tilePositionSettings', tilePositionSettings);
    }
  }, [locationData?.locationKey]);

  useEffect(() => {
    if (locationData && locationData.locationKey) {
      let rotateY = deg2rad(locationData?.degrees + rotationOffset - locationData?.rotation?.y);
      let rotateX = 0;
      let rotateZ = 0;

      if (spaceData.adjustPanoramaVerticalAlignment) {
        rotateX = deg2rad(locationData.rotation?.x);
        rotateZ = deg2rad(locationData.rotation?.z);
      }

      let rotate = [rotateX, rotateY, rotateZ];

      setRotate(rotate);

      createTilePositionSettings();

      onReady(locationData.locationKey);
    }
  }, [locationData?.locationKey]);

  useEffect(() => {
    let shouldVisible = true;
    const handleOnRequestNewLocation = () => {
      shouldVisible = false;
      tilesGroupRef.current.children.forEach((tileMesh) => {
        tileMesh.visible = false;
      });
    };

    const handleOnArrived = () => {
      shouldVisible = true;
    };

    const handleCameraChangeToSleep = () => {
      if (shouldVisible) {
        shouldVisible = false;
        tilesGroupRef.current.children.forEach((tileMesh) => {
          tileMesh.visible = true;
        });
      }
    };

    navigationManager.onRequestNewLocation(handleOnRequestNewLocation);
    navigationManager.onArrived(handleOnArrived);
    cameraStateObserver.onChangeToSleep(handleCameraChangeToSleep);

    return () => {
      navigationManager.offRequestNewLocation(handleOnRequestNewLocation);
      navigationManager.offArrived(handleOnArrived);
      cameraStateObserver.offChangeToSleep(handleCameraChangeToSleep);
    };
  }, []);

  useEffect(() => {
    if (!camera.layers.isEnabled(0)) {
      enableLayersTimeout.current = setTimeout(() => {
        camera.layers.enableAll();
      }, 500);
    }
    return () => {
      if (enableLayersTimeout.current) {
        clearTimeout(enableLayersTimeout.current);
      }
    };
  }, [tilePositions]);

  return (
    <group ref={ref} rotation={rotate} visible={false}>
      <mesh ref={meshRef} geometry={boxGeometry} renderOrder={90} visible={false}></mesh>
      <group ref={tilesGroupRef}>
        {tilePositions &&
          tilePositions.map((tile, index) => (
            <ImageTile
              key={index}
              minPosition={tile.minPosition}
              maxPosition={tile.maxPosition}
              textureUrl={tile.textureUrl}
              visible={true}
            />
          ))}
      </group>
    </group>
  );
}

export default forwardRef(LocationTiledImageCube);
