import tagService from '@apis/services/tag.service';
import useTagsStatus from '@features/3d-Space/tags/hooks/useTagsStatus';
import useBackgroundMusic from '@hooks/useBackgroundMusic';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { Box, CircularProgress } from '@material-ui/core';

export const MediaModalBody = (props) => {
  const { currentTagId } = useTagsStatus();
  const [isLoading, setIsLoading] = useState(true);

  const tagData = useMemo(() => tagService.getTagById(currentTagId), [currentTagId]);

  const handleReady = () => {
    setIsLoading(false);
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  return (
    <>
      {tagData && (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 20,
            overflow: 'hidden',
          }}
        >
          {tagData.media.src && isImage(tagData.media.src) ? (
            <ImageContainer src={tagData.media.src} onLoad={handleReady} />
          ) : (
            <VideoContainer src={tagData.media.src} onLoad={handleReady} />
          )}
          <Box sx={{ display: isLoading ? 'block' : 'none', position: 'absolute' }}>
            <CircularProgress />
          </Box>
        </Box>
      )}
    </>
  );
};

const ImageContainer = ({ src, onLoad }) => {
  return (
    <Box
      sx={{
        width: 'inherit',
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <img
        src={src}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        onLoad={onLoad}
      />
    </Box>
  );
};

const VideoContainer = ({ src, onLoad }) => {
  const { isPlaying, playBackgroundMusic, pauseBackgroundMusic } = useBackgroundMusic();
  const backgroundMusicPlayRef = useRef();


  console.log('VideoContainer start')
  useEffect(() => {
    backgroundMusicPlayRef.current = isPlaying;
    return () => {
      if (backgroundMusicPlayRef.current) {
        playBackgroundMusic();
      }
    };
  }, []);

  const handlePlayerReady = () => {
    console.log('VideoContainer on ready')
    onLoad();
    if (isPlaying) {
      pauseBackgroundMusic();
    }
  };

  return (
    <ReactPlayer
      url={src}
      playing={true}
      width="inherit"
      height="inherit"
      loop
      style={{ outline: 'none', backgroundColor: 'black' }}
      onReady={handlePlayerReady}
      controls
      volume={0.5}
    />
  );
};
