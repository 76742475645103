const tags = [
  {
    id: '1',
    type: 'video',
    face: {
      x: 0.6706604538564784,
      y: 0.38909891499713106,
      z: 0.6315192712665842,
    },
    position: {
      x: 27.09173324615783,
      y: 1.5368221580532602,
      z: 25.271643377862542,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/1.mp4',
    },
  },
  {
    id: '2',
    type: 'video',
    face: {
      x: 0.7164681997507502,
      y: 0.46360660436459594,
      z: -0.5212890130584455,
    },
    position: {
      x: 23.399366110938672,
      y: 1.5495513248576351,
      z: 24.885464396036994,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/2.mp4',
    },
  },
  {
    id: '3',
    type: 'video',
    face: {
      x: 0.5000230941688395,
      y: 0.8659492928180418,
      z: 0.010427251112026047,
    },
    position: {
      x: 26.3498315470276,
      y: 0.9009235451283412,
      z: 27.95455556747656,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/3.mp4',
    },
  },
  {
    id: '4',
    type: 'video',
    face: {
      x: -0.014845051162242742,
      y: 0.09589795104337141,
      z: -0.9952804667236635,
    },
    position: {
      x: 24.208192362015954,
      y: 1.7786055171594592,
      z: 31.781561081194724,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/4.mp4',
    },
  },
  {
    id: '5',
    type: 'video',
    face: {
      x: 0.16748828940600374,
      y: 0.983208730836376,
      z: -0.07244490678421223,
    },
    position: {
      x: 27.907769709485347,
      y: 0.6804560413197094,
      z: 16.967755105410212,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/5.mp4',
    },
  },
  {
    id: '6',
    type: 'video',
    face: {
      x: 0.03808775549153375,
      y: 0.20729577430306018,
      z: 0.9775365900250037,
    },
    position: {
      x: 26.44754999159548,
      y: 1.7596511337096414,
      z: 14.297301080360278,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/6.mp4',
    },
  },
  {
    id: '7',
    type: 'video',
    face: {
      x: -0.3508384390878362,
      y: 0.7975234280788999,
      z: 0.49078383360058875,
    },
    position: {
      x: 16.316284971700725,
      y: 1.2094703373219757,
      z: 16.25232676741211,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/7.mp4',
    },
  },
  {
    id: '8',
    type: 'video',
    face: {
      x: -0.35649750730858765,
      y: 0.014016504533034787,
      z: 0.9341911286687745,
    },
    position: {
      x: 18.42337344145677,
      y: 1.5731766943329695,
      z: 14.140047010452811,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/8.mp4',
    },
  },
  {
    id: '9',
    type: 'video',
    face: {
      x: -0.015835818991700785,
      y: 0.9828763963136603,
      z: -0.18358435773871032,
    },
    position: {
      x: 16.394757190663608,
      y: 0.932011586287102,
      z: 17.958258545911722,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/9.mp4',
    },
  },
  {
    id: '10',
    type: 'video',
    face: {
      x: 0.1222125880077461,
      y: 0.9906501796650242,
      z: 0.06063253963099327,
    },
    position: {
      x: 14.012075019119592,
      y: 0.9185479775006217,
      z: 29.39095597730239,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/10.mp4',
    },
  },
  {
    id: '11',
    type: 'video',
    face: {
      x: 0.19872910685034445,
      y: 0.04284970251626486,
      z: -0.9791172785140358,
    },
    position: {
      x: 9.02330236313158,
      y: 1.7288819778338047,
      z: 31.748519385441192,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/11.mp4',
    },
  },
  {
    id: '12',
    type: 'video',
    face: {
      x: -0.2258050814360681,
      y: 0.8146233161388354,
      z: 0.5342292747506612,
    },
    position: {
      x: 8.26115121525428,
      y: 1.6568221377906756,
      z: 19.66855669100441,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/12.mp4',
    },
  },
  {
    id: '13',
    type: 'video',
    face: {
      x: 0.7516091341658079,
      y: 0.6299517453278729,
      z: -0.1955620310717068,
    },
    position: {
      x: 8.383192749443367,
      y: 1.5952420542477241,
      z: 15.535785051818069,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/13.mp4',
    },
  },
  {
    id: '14',
    type: 'video',
    face: {
      x: -0.0073072188913392705,
      y: 0.5509688048879813,
      z: 0.8344938469469884,
    },
    position: {
      x: 8.912971552461855,
      y: 1.5996105548378092,
      z: 13.737246113987682,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/14.mp4',
    },
  },
  {
    id: '15',
    type: 'video',
    face: {
      x: -0.08611846164332405,
      y: 0.9932550609098558,
      z: 0.07764016062029928,
    },
    position: {
      x: 9.14673941840237,
      y: 1.0995780757084668,
      z: 10.80398397649807,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/15.mp4',
    },
  },
  {
    id: '16',
    type: 'video',
    face: {
      x: 0.531256586439272,
      y: 0.3943258607467536,
      z: 0.7498490214111267,
    },
    position: {
      x: 5.202598428680419,
      y: 1.5783157528768832,
      z: 12.937857166811467,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/16.mp4',
    },
  },
  {
    id: '17',
    type: 'video',
    face: {
      x: 0.010923391902955862,
      y: 0.9882004163278062,
      z: 0.1527763616495861,
    },
    position: {
      x: 4.979720989940914,
      y: 0.968796158463782,
      z: 9.039126761020288,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/17.mp4',
    },
  },
  {
    id: '18',
    type: 'video',
    face: {
      x: 0.5582111868647421,
      y: 0.762322834836516,
      z: 0.3275120858012324,
    },
    position: {
      x: 2.0080235040226313,
      y: 1.0530678433306988,
      z: 10.994654232391854,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/18.mp4',
    },
  },
  {
    id: '19',
    type: 'video',
    face: {
      x: -0.025546716349620895,
      y: 0.9987152130687346,
      z: -0.043764008829448024,
    },
    position: {
      x: 5.191300716352297,
      y: 1.2396974456643712,
      z: 2.1132571620870477,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/19.mp4',
    },
  },
  {
    id: '20',
    type: 'video',
    face: {
      x: -0.014059970223809275,
      y: 0.9537076261177778,
      z: 0.30040652643059945,
    },
    position: {
      x: 2.6490681080493,
      y: 0.9754478064483121,
      z: 2.379025904160401,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/20.mp4',
    },
  },
  {
    id: '21',
    type: 'video',
    face: {
      x: -0.20999799361576982,
      y: 0.9404272053386606,
      z: 0.26739019080038734,
    },
    position: {
      x: 9.814809366295677,
      y: 0.9351285612560666,
      z: 4.088446058880429,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/21.mp4',
    },
  },
  {
    id: '22',
    type: 'video',
    face: {
      x: 0.9179576966654378,
      y: 0.23158369806107934,
      z: 0.3220600222397043,
    },
    position: {
      x: -0.5681305112093835,
      y: 1.6051966468782943,
      z: 22.33794733159931,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/22.mp4',
    },
  },
  {
    id: '23',
    type: 'video',
    face: {
      x: 0.7978472029210029,
      y: 0.47515456032514847,
      z: -0.37103636559419223,
    },
    position: {
      x: -6.737595095785204,
      y: 1.5235938185585016,
      z: 25.17292135370537,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/23.mp4',
    },
  },
  {
    id: '24',
    type: 'video',
    face: {
      x: 0.9799207602195742,
      y: 0.059784292215741885,
      z: 0.19021341197443067,
    },
    position: {
      x: -9.835212685492701,
      y: 1.7228542588368907,
      z: 25.315409267994074,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/24.mp4',
    },
  },
  {
    id: '25',
    type: 'video',
    face: {
      x: 0.14641926552723367,
      y: 0.9867410304185953,
      z: 0.07002526380467249,
    },
    position: {
      x: -5.669602852131675,
      y: 0.8487171627858734,
      z: 22.60383683524467,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/25.mp4',
    },
  },
  {
    id: '26',
    type: 'video',
    face: {
      x: -0.028208676662493718,
      y: 0.9991431730442842,
      z: 0.03028515015564621,
    },
    position: {
      x: -1.078829460213138,
      y: 0.9370894662313217,
      z: 17.674395156536562,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/26.mp4',
    },
  },
  {
    id: '27',
    type: 'video',
    face: {
      x: -0.006528988212476275,
      y: 0.9796414329147433,
      z: 0.2006490349582325,
    },
    position: {
      x: -3.5449446390918915,
      y: 1.1550849534764502,
      z: 15.559291555772642,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/27.mp4',
    },
  },
  {
    id: '28',
    type: 'video',
    face: {
      x: 0.9970957205718629,
      y: 0.03577875081630827,
      z: 0.06723098249543918,
    },
    position: {
      x: -0.7624698635689252,
      y: 1.8837037612839946,
      z: 32.93414594572817,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/28.mp4',
    },
  },
  {
    id: '29',
    type: 'video',
    face: {
      x: 0.914361058063657,
      y: 0.3527844551308882,
      z: -0.19871332042596473,
    },
    position: {
      x: -8.190272963217442,
      y: 1.5134843548057717,
      z: 33.424159256640884,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/29.mp4',
    },
  },
  {
    id: '30',
    type: 'video',
    face: {
      x: 0.5401322978949196,
      y: 0.07966423719277825,
      z: -0.8378011160670812,
    },
    position: {
      x: -11.899877416057207,
      y: 1.4928660615355573,
      z: 35.109656189773915,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/30.mp4',
    },
  },
  {
    id: '31',
    type: 'video',
    face: {
      x: 0.8135668213238683,
      y: 0.11210349869459692,
      z: -0.5705627334670637,
    },
    position: {
      x: -2.90556813095304,
      y: 1.6792761580543138,
      z: 35.96332052344061,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/31.mp4',
    },
  },
  {
    id: '32',
    type: 'video',
    face: {
      x: -0.09874020013094831,
      y: 0.9913311756971687,
      z: -0.08667683063523744,
    },
    position: {
      x: -4.177304679224212,
      y: 1.109809613451076,
      z: 39.457789155224425,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/32.mp4',
    },
  },
  {
    id: '33',
    type: 'video',
    face: {
      x: -0.11495123601424406,
      y: 0.9932567908540049,
      z: -0.015071853276923734,
    },
    position: {
      x: -2.1011158646285946,
      y: 1.406881806948285,
      z: 40.78605104960446,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/33.mp4',
    },
  },
  {
    id: '34',
    type: 'video',
    face: {
      x: 0.37255562770254314,
      y: 0.7514831042487886,
      z: -0.5444955907036975,
    },
    position: {
      x: 3.4167243714265565,
      y: 1.1989813626557493,
      z: 33.073166253005766,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/34.mp4',
    },
  },
  {
    id: '35',
    type: 'video',
    face: {
      x: -0.9850538394351908,
      y: 0.11615153949301495,
      z: 0.12719179724884805,
    },
    position: {
      x: 6.162312766883709,
      y: 1.6009408840532837,
      z: 38.47219362660141,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/35.mp4',
    },
  },
  {
    id: '36',
    type: 'video',
    face: {
      x: -0.007049750464824829,
      y: 0.11961071183868303,
      z: 0.9927958393505822,
    },
    position: {
      x: 26.40428771177045,
      y: 2.7296512683026597,
      z: 13.874083408769339,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/36.mp4',
    },
  },
  {
    id: '37',
    type: 'video',
    face: {
      x: -0.0059899213027048755,
      y: 0.06777582778234638,
      z: 0.9976825938199008,
    },
    position: {
      x: 21.597073369363773,
      y: 1.9481927846158085,
      z: 13.97940677942808,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/37.mp4',
    },
  },
  {
    id: '38',
    type: 'video',
    face: {
      x: -0.047147205774387656,
      y: 0.05123755060880495,
      z: 0.9975729819894269,
    },
    position: {
      x: 14.720316886375368,
      y: 2.516758915110146,
      z: 13.903534804468233,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/38.mp4',
    },
  },
  {
    id: '39',
    type: 'video',
    face: {
      x: 0.9997822444178258,
      y: -0.002961429788287676,
      z: 0.020656565069336556,
    },
    position: {
      x: -1.4147463039116237,
      y: 2.7639177173742047,
      z: 9.24860846927594,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/39.mp4',
    },
  },
  {
    id: '40',
    type: 'video',
    face: {
      x: 0.5709018527618726,
      y: 0.06903286877995286,
      z: 0.8181109567418536,
    },
    position: {
      x: 9.718122097564931,
      y: 2.019640823219858,
      z: 18.022998499978243,
    },
    media: {
      src: 'https://fidelitystorageprod-cdn.azureedge.net/cottonon-chadstone/default/videos/40.mp4',
    },
  },
];

export default tags;
